class AmplitudeTracking {
  init() {
    if (!window.amplitude) {
      return
    }
    amplitude.getInstance().init("a5926df1040f56c199829c57555c4c97")
    this.initialized = true
  }

  logEvent(event, data) {
    if (!this.initialized || !event) {
      return
    }
    amplitude.getInstance().logEvent(event, data)
  }
}

export default new AmplitudeTracking()
